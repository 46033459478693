.tab-element {
    .nav {
        @extend .pb-2;
        @extend .border-bottom;
        border-color: theme-color('primary-2') !important;
        @extend .justify-content-center;
    }
    .nav-link {
        @extend .px-5;
    }

    .nav-item {
        @extend .mx-1;
        @extend .text-center;
        @include media-breakpoint-down (sm) {
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-top: 2em;
        }

        .eq {
            height: 84px;
            @include media-breakpoint-down (sm) {
                height: 46px;
            }
        }

        .label {
            // @extend .h4;
            @extend .mt-3;
            font-family: $condensed-font-family;
            // font-size: 1em;
            // color: theme-color('primary') !important;
            @include media-breakpoint-down (sm) {
                // display: none;
                font-size: 0.75em;
            }
                    
        }
    }

    .tab-content {
        @extend .mt-4;
        border-color: theme-color('primary') !important;
    }

    .nav-link {
        filter: grayscale(100%);
        opacity: 0.3 !important;
        transition: all 0.5s ease-out;

        &.active,
        &:hover {
            filter: brightness(100%);
            opacity: 1 !important;
        }
    }
}
