.card {
    border: none;
    min-height: 380px;
    overflow: hidden;
    border-radius: 0 !important;
    @extend .layer-1;
    // @extend .shadow-lg;
    background: #efefef;
}

.card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.card-body {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    p {
        // color: theme-color('primary-2');
    }

}
