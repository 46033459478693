.accordion {
    .border-top {
        // border-color: theme-color('primary-2') !important;
        border-color: black !important;
    }

    .accordion-panel-title {
        @extend h3;
        @extend .pt-2;
        // color: theme-color('primary-2') !important;
        path {
            fill: theme-color('primary');
        }
    }

    .accordion-content {
        // border-color: theme-color('primary-2') !important;
        border-color: black !important;
        color: #666;
    }

}
