.header-section {
    @extend .header-inner;
    @extend .p-0;
    @extend .jarallax;
    @extend .position-relative;
    // @extend  .bg-primary-3;
    background: rgba(0,0,0,.6);
    background: linear-gradient(180deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,.6) 100%);

    // margin-top: 8em;

    @include media-breakpoint-down (sm) {
        margin-top: 2em;
    }

    @extend .o-hidden;

    .header-content {
        @extend .container;
        @extend .py-0;

        .row {
            @extend .layer-2;
            @extend .my-4;
            @extend .my-md-6;
        }
    }


    h1 {
        color: white !important;
        @extend .display-3;
        @extend .mb-0;
    }


    &.story {
        @extend .pt-6;
        @extend .mb-0;
        @extend .bg-white;

        h1 {
            color: $white;
        }

        .header-content {
            min-height: initial;
        }
    }


    p {
        &.h1 {
            @extend h1;
            color: white !important;
        }
    }

    .lead {
        @extend .mb-0;
    }

    div[id*="jarallax-container"] {
        mix-blend-mode: multiply;
    }

    &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        mix-blend-mode: multiply;
        background: rgba(0,0,0,.6);
        background: linear-gradient(180deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,.6) 100%);
    }

    .btn {
        // font-size: 1.25em !important;
        @extend .mt-4;
        background: none;
        color: white;

        &:hover {
            background: white;
            color: theme-color('primary-2');
        }
    }
}


.header-content {
    min-height: 350px;

    @media (min-width: 1366px) and (max-width: 1680px) {
        min-height: 280px;
    }
    @media (min-width: 960px) and (max-width: 1366px) {
        min-height: 220px;
    }
    @include media-breakpoint-down(md) {
        min-height: 180px;
    }
}