// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$transition-time: .2s;
$transition-time-fast: .1s;
$transition-time-slow: .4s;

// Sections

$default-section-padding: $spacer*2;

// Color Scheme
//

$theme-color-scheme: ();
$theme-color-scheme: map-merge(
  (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3
  ),
  $theme-color-scheme
);

// Functions

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  
  @return $string;
}

$primary-escaped: str-replace(#{$primary}, '#', '%23');
$body-color-escaped: str-replace(#{$body-color},'#','%23');

// Shadows

$shadow-3d: .5rem .5rem 0 rgba($gray-200,.5);


$font-family-sans-serif:      'IBM Plex Sans', sans-serif;
$font-family-base:            'IBM Plex Sans', serif;
$headings-font-family:        'IBM Plex Sans', sans-serif;

$font-weight-light:           200;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-bold:            700;

$font-size-base:              1.125rem;
$lead-font-size:              $font-size-base * 1.333333;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.125;
$h4-font-size:                $font-size-base * 1;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$headings-font-weight:         700;
