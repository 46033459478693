footer {
    // @extend .d-flex;
    @extend .pb-3;
    @extend .pt-0;

    .container {
        @extend .d-flex;
        @extend .flex-column;
    }

    min-height: 350px;

    .divider {
        transform: scaleX(-1);
    }

    .navbar-brand {
        font-weight: $font-weight-normal;
    }

    .btn {
        color: color('white');
        font-weight: $font-weight-normal;
    }

    font-family: $headings-font-family;
    // font-weight: $font-weight-bold;

    // background: theme-color('primary-2');
    color: color('white') !important;

    .footer-nav {
        @extend .nav;
        font-size: 1.25em !important;

        .nav-link {
            @extend .px-md-1;
        }

        .nav-item {
            @extend .d-none;
            @extend .d-md-block;

            &:last-child {
                a {
                    @extend .pr-0;
                }
            }
        }
    }

    .meta-nav {
        @extend .nav;
        // @extend .flex-column;

        .nav-item {
            position: relative;

            &:after {
                content: '/';
                position: absolute;
                top: 0.65em;
                left: -.125em;
            }

            &:first-child {
                .nav-link {
                    @extend .pl-0;
                }
                &:after {
                    content: '' !important;
                }
            }

            &:last-child {
                .nav-link {
                    @extend .pr-0;
                }
            }
        }

        .nav-link {
            @extend .pb-0;
            @extend .px-1;
            text-decoration: underline;
        }
    }

    .social-nav {
        @extend .nav;

        .nav-item {
            // @extend .col-sm-3;
            @extend .ml-2;

            a {
                background: white;
                display: block;
                width: 2em;
                height: 2em;
                color: theme-color('primary-2');
                position: relative;

                i {
                    margin: 0;
                    font-size: 1.5em;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-right: -50%;
                    transform: translate(-50%, -50%)
                }

                &:hover {
                    background: theme-color('primary-3');
                    // color: color('white');
                }
            }
        }
    }

    a {
        font-weight: $font-weight-normal;
        color: white;

        &:hover {
            color: white;
            text-decoration: underline;
        }
    }

    .nav {

        .link-app,
        .nav-link {
            @extend .mx-2;
            color: white !important;
            font-size: 1em;
            opacity: 1 !important;
            font-weight: $font-weight-normal;
            &:hover {
                color: theme-color('primary') !important;
            }
        }
    }

    .font-regular {
        font-family: $font-family-base;
        font-size: 14px;
        font-weight: $font-weight-normal;

        .nav-link {
            font-weight: $font-weight-normal !important;
            font-size: 14px;
        }
    }



    // div[id*="jarallax-container"] {
    //     mix-blend-mode: multiply;
    // }

    &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        mix-blend-mode: multiply;
        background: rgba(0,0,0,.6);
        background: linear-gradient(180deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,.6) 100%);
    }


    // background-color: theme-color('primary');

    // @extend .bg-verlauf;

    // @Julien, das wird wegen der Grafik oben evtl. nicht gehen.
    // background-image: linear-gradient(225deg, theme-color('primary'), theme-color('primary-1'));
}
