// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Uncomment the variables below and change the hex values.

$primary:      #CF2030;
$primary-2:     #19356C;
$primary-3:     #495057;
// $primary-4:     #e2f0cd;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);

$border-radius: 20px;
$border-width: 2px;
$border-color: $primary;

$font-family-sans-serif:      'IBM Plex Sans', sans-serif;
$font-family-base:            'IBM Plex Sans', sans-serif;
$headings-font-family:        'IBM Plex Sans', sans-serif;
$condensed-font-family:       'IBM Plex Sans Condensed', sans-serif;

$font-weight-light:           200;
$font-weight-normal:          400;
$font-weight-medium:          700;
$font-weight-bold:            700;

$headings-font-weight:        200;
$display1-weight:             200;
$display2-weight:             700;
$display3-weight:             200;
$display4-weight:             200;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 576px,
  md: 960px,
  lg: 1366px,
  xl: 1440px
);